/**
 * @file Configuration overrides for the client.
 */

import Modules from '@verosource/myhealth-web/src/constants/modules';

/** @type {import('@verosource/myhealth-web/src/config/appConfig').AppConfig} */
const appConfig = {
  myhealthWebVersion: process.env.REACT_APP_MYHEALTH_WEB_VERSION,
  onlyAllowEmbedded: true,
  modules: {
    [Modules.LAB_RESULTS]: {
      defaultLowerBoundary: 12,
      absoluteLowerBoundary: new Date(2010, 0),
    },
    [Modules.IMAGING]: {
      defaultLowerBoundary: 12,
      absoluteLowerBoundary: new Date(2023, 5),
    },
    [Modules.MEDICATIONS]: {
      defaultLowerBoundary: 15,
      absoluteLowerBoundary: new Date(2016, 11),
    },
  },
};

export default appConfig;
